import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label, Button, Col } from "reactstrap";
import {
  crearProgramacion,
  updateProgramacion,
  llenarCamion,
  llenarTipo,
  llenarCliente,
  llenarProducto,
} from "../../api/programacionDiaria";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const CrearEditarProgramacion = (props) => {
  const [editar, setEditar] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [programacion, setProgramacion] = useState([]);
  const [loadingCrearProgramacion, setLoadingCrearProgramacion] =
    useState(false);

  const [state, setState] = useState({
    fecha: "",
    tipo: "",
    proveedor: "",
    material: "",
    direccion: "",
    camiones: "",
    id: "",
    peso_lleno_enviado: "",
    peso_vacio_enviado: "",
    descuento_enviado: "",
    peso_neto_enviado: "",
    toneladas_netas: "",
    ticket_recibido: "",
    peso_lleno_cliente: "",
    peso_vacio_cliente: "",
    descuento_cliente: "",
    peso_neto_cliente: "",
    pacas_bultos: "",
    estado_prog: "",
  });
  const [editState, setEditState] = useState({
    edit: "",
    valor: "",
    id: "",
  });

  const [cod_clienteplan, setcodclienteplan] = useState("");
  const [placasplan, setplacasplan] = useState("");
  const [cod_productoplan, setcodproductoplan] = useState("");

  const [respaldoEditField, setRespaldoEditField] = useState("");

  const [camiones, setcamiones] = useState([]);
  const [productos, setproductos] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [tiposcomb, settiposcomb] = useState([]);

  const [comboCliente, setcliente] = useState([]);

  const llenarClientes = async () => {
    const rest = await llenarCliente();
    if (rest[0] !== "No hay clientes registrados") {
      setcliente(rest[0]);
    }
  };

  const llenarCamiones = async () => {
    const resto = await llenarCamion();
    console.log("desde crear editar programacion... ", resto[0]);
    if (resto[0] !== "No hay Linea") {
      console.log("en la api llenar camion... ", resto);

      setcamiones(resto[0]);
      console.log("contenido setCamiones", camiones);
    }
  };
  const llenarTipos = async () => {
    const restos = await llenarTipo();
    console.log("desde crear editar programacion... ", restos[0]);

    if (restos[0] !== "No hay Linea") {
      setTipos(restos[0]);
      console.log(restos[0]);
    }
  };
  const llenarProductos = async () => {
    const rest = await llenarProducto();
    if (rest[0] !== "No hay prodcutos") {
      console.log("*****************");
      console.log(rest);
      setproductos(rest[0]);
    }
  };

  useEffect(() => {
    llenarTipos();
    llenarClientes();
    llenarCamiones();
    llenarProductos();

    if (props.selectData) {
      setEditar(true);
      initEditar();
    }
  }, []);
  const habilitarEdicion = (e, edit) => {
    e.preventDefault();
    if (editState.edit === "") {
      setEditState({
        ...editState,
        edit: edit,
        id:
          edit === "proveedor"
            ? props.selectData.personas[0].persona_id
            : props.selectData.id,
      });
      setRespaldoEditField(state[edit]);
    } else {
      alert(
        "otro campo se encuentra en edicion , por favor guarde o cancele los cambios para continuar "
      );
    }
  };
  const cancelarEdicion = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [editState.edit]: respaldoEditField,
    });
    setEditState({
      ...editState,
      edit: "",
      valor: "",
    });
    setRespaldoEditField("");
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    const res = await updateProgramacion(editState);
    switch (res[0].sms) {
      case "ok":
        toast.success(`${res[0].mensaje}`, {
          theme: "colored",
        });
        setButtonState(false);
        setEditState({
          ...editState,
          edit: "",
          id: "",
          valor: "",
        });

        break;
      default:
        toast.error(`${res[0].mensaje}`, {
          theme: "colored",
        });
        setButtonState(false);
        break;
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.edit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }
    return editar ? (
      <Label sm={12}>
        {editState.edit !== campoeditar ? (
          <button
            className="btn btn-primary btn-sm"
            onClick={(e) => habilitarEdicion(e, campoeditar)}
          >
            editar
          </button>
        ) : editState.edit === campoeditar ? (
          <div className="">
            <button
              className="btn btn-danger btn-sm mx-1"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById(campoeditar).disabled = true;
                cancelarEdicion(e);
              }}
            >
              x
            </button>
            <button
              className="btn btn-primary btn-sm "
              disabled={editState.valor === "" ? true : false}
              onClick={(e) => editarFn(e)}
            >
              guardar
            </button>
          </div>
        ) : null}
      </Label>
    ) : null;
  };
  const ed = (name, valor) => {
    if (editState.edit === name) {
      setEditState({
        ...editState,
        valor: valor,
      });
    }
  };

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const guardarProgramacion = () => {
    saveProgramacion();
  };

  const saveProgramacion = async () => {
    setLoadingCrearProgramacion(true);
    console.log("valor de combo tipo... ", tiposcomb);
    const respuesta = await crearProgramacion(state);
    if (respuesta[0].sms === "ok") {
      toast.success(respuesta[0].mensaje, { position: "bottom-left" });
      cerrarModalActualizarTablaProgramacion();
    } else {
      toast.error(respuesta[0].mensaje, { position: "bottom-left" });
    }
    setLoadingCrearProgramacion(false);
  };

  const cerrarModalActualizarTablaProgramacion = () => {
    props.crearrModalActualizar();
  };

  const initEditar = () => {
    setState({
      ...state,
      fecha: moment(props.selectData.fecha).format("YYYYY-MM-DD"),
      material: props.selectData.codigo_producto,
      camiones: props.selectData.placas,
      direccion: props.selectData.direccion_cliente,
      tipo: props.selectData.tipo_transaccion,
      proveedor: props.selectData.cliente_entregado,
      peso_lleno_enviado: props.selectData.peso_lleno_enviado,
      peso_vacio_enviado: props.selectData.peso_vacio_enviado,
      descuento_enviado: props.selectData.descuento_enviado,
      peso_neto_enviado: props.selectData.peso_neto_enviado,
      toneladas_netas: props.selectData.toneladas_netas,
      ticket_recibido: props.selectData.ticket_recibido,
      peso_lleno_cliente: props.selectData.peso_lleno_cliente,
      peso_vacio_cliente: props.selectData.peso_vacio_cliente,
      descuento_cliente: props.selectData.descuento_cliente,
      peso_neto_cliente: props.selectData.peso_neto_cliente,
      pacas_bultos: props.selectData.pacas_bultos,
      estado_prog: props.selectData.estado_prog,
      id: props.selectData.id,
    });

    setEditState({
      ...editState,
      id: props.selectData.id,
    });
  };

  const editaux = () => {};

  return (
    <div className="row">
      <Form inline className="col-md-12 ">
        <div className="row">
          <div
            className={
              editar ? "col-md-4 bordecustom formx" : "col-md-12 formx"
            }
          >
            <FormGroup row className="d-flex align-items-center">
              <Label for="fecha" sm={12}>
                fecha :
              </Label>
              <Col sm={12}>
                <Input
                  id="fecha"
                  name="fecha"
                  value={state.fecha}
                  placeholder="Ingrese el usuario"
                  type="date"
                  onChange={onchange}
                />
              </Col>
            </FormGroup>{" "}
            <FormGroup row className="d-flex align-items-center">
              <Label for="tipo" sm={12}>
                Tipo Transacción :
              </Label>
              <Col sm={12}>
                <select
                  id="tipo"
                  name="tipo"
                  value={state.tipo}
                  className="form-control"
                  onChange={onchange}
                  //onChange={(e) => setplacasplan(e.target.value)}
                >
                  <option id="tipo" value="">
                    Seleccione Tipos
                  </option>
                  {tipos.map((iten) => (
                    <option id="tipo" value={iten.nombre_comb}>
                      {iten.nombre_comb}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>
            <FormGroup row className="d-flex align-items-center">
              <Label for="proveedor" sm={12}>
                proveedor :
              </Label>
              <Col sm={12}>
                <select
                  className="form-control"
                  id="proveedor"
                  name="proveedor"
                  value={state.proveedor}
                  onChange={onchange}
                >
                  <option id="proveedor" value="">
                    Seleccione Cliente
                  </option>
                  {comboCliente.map((iten) => (
                    <option id="proveedor" value={iten.nombres_cliente}>
                      {iten.nombres_cliente}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>{" "}
            <FormGroup row className="d-flex align-items-center">
              <Label for="material" sm={12}>
                material :
              </Label>
              <Col sm={12}>
                <select
                  id="material"
                  name="material"
                  value={state.material}
                  className="form-control"
                  onChange={onchange}
                >
                  <option id="material" value="">
                    Seleccione Producto
                  </option>
                  {productos.map((iten) => (
                    <option id="material" value={iten.item}>
                      {iten.sub_linea}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>{" "}
            <FormGroup row className="d-flex align-items-center">
              <Label for="material" sm={12}>
                direccion :
              </Label>
              <Col sm={12}>
                <Input
                  id="direccion"
                  name="direccion"
                  placeholder="Ingrese la direccion del retiro"
                  type="text"
                  value={state.direccion}
                  onChange={onchange}
                />
              </Col>
            </FormGroup>{" "}
            <FormGroup row className="d-flex align-items-center">
              <Label for="material" sm={12}>
                camion :
              </Label>
              <Col sm={12}>
                <select
                  id="camiones"
                  name="camiones"
                  value={state.camiones}
                  className="form-control"
                  onChange={onchange}
                >
                  <option id="camion" value="">
                    Seleccione Camion
                  </option>
                  {camiones.map((iten) => (
                    <option id="camion" value={iten.nombre_comb}>
                      {iten.nombre_comb}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>
          </div>
          {editar ? (
            <div className="col-md-4 bordecustom" bordecustom>
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="Peso Lleno Local" sm={12}>
                      Peso lleno local :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_lleno_local"
                        name="peso_lleno_local"
                        value={state.peso_lleno_enviado}
                        placeholder="Ingrese el peso lleno local"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="Peso Lleno Local" sm={12}>
                      Peso vacio local :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_vacio_local"
                        name="peso_vacio_local"
                        value={state.peso_vacio_enviado}
                        placeholder="Ingrese el peso vacio local"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="Peso desct Local" sm={12}>
                      Peso Desct local :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="descuento_enviado"
                        name="descuento_enviado"
                        value={state.descuento_enviado}
                        placeholder="Ingrese descuento enviado"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="Peso neto Local" sm={12}>
                      Peso Neto local :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_neto_enviado"
                        name="peso_neto_enviado"
                        value={state.peso_neto_enviado}
                        placeholder="Peso neto enviado"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="toneladas" sm={12}>
                      toneladas :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="toneladas_netas"
                        name="toneladas_netas"
                        value={state.toneladas_netas}
                        placeholder="Toneladas Netas"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="ticket peso" sm={12}>
                      Tickets Peso :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="ticket_recibido"
                        name="ticket_recibido"
                        value={state.ticket_recibido}
                        placeholder="ticket recibido"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {!editar ? (
                <Button
                  color="primary"
                  className="w-100"
                  disabled={loadingCrearProgramacion ? true : false}
                  onClick={(e) => {
                    guardarProgramacion();
                  }}
                >
                  Guardar nueva Programacion
                </Button>
              ) : null}
            </div>
          ) : null}
          {editar ? (
            <div className="col-md-4 " bordecustom>
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="Peso lleno cliente" sm={12}>
                      Peso Lleno Cliente :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_lleno_cliente"
                        name="peso_lleno_cliente"
                        value={state.peso_lleno_cliente}
                        placeholder="peso lleno cliente"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="peso vacio cliente" sm={12}>
                      Peso Vacio Cliente :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_vacio_cliente"
                        name="peso_vacio_cliente"
                        value={state.peso_vacio_cliente}
                        placeholder="Peso Vacio Cliente"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="descuento cliente" sm={12}>
                      Descuento Cliente :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="descuento_cliente"
                        name="descuento_cliente"
                        value={state.descuento_cliente}
                        placeholder="Descuento Cliente"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="peso neto cliente" sm={12}>
                      Peso Neto Cliente :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="peso_neto_cliente"
                        name="peso_neto_cliente"
                        value={state.peso_neto_cliente}
                        placeholder="Peso Neto Cliente"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="diferencias peso" sm={12}>
                      Diferencias de Pesos :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="diferencia_pesos"
                        name="diferencia_pesos"
                        value={
                          state.peso_neto_cliente - state.peso_neto_enviado
                        }
                        placeholder="Diferencia Pesos"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <>
                  <FormGroup row className="d-flex align-items-center">
                    <Label for="pacas bultos" sm={12}>
                      Pacas Bultos :
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="pacas_bultos"
                        name="pacas_bultos"
                        value={state.pacas_bultos}
                        placeholder="Pacas Bultos"
                        type="text"
                        onChange={onchange}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : null}
              {editar ? (
                <FormGroup row className="d-flex align-items-center">
                  <Label for="Estado" sm={12}>
                    Estado :
                  </Label>
                  <Col sm={12}>
                    <select
                      className="form-control"
                      id="estado_prog"
                      name="estado_prog"
                      value={state.estado_prog}
                      onChange={onchange}
                    >
                      <option value="">Seleccione estado</option>
                      <option value="Planificado">Planificado</option>
                      <option value="Reprogramado">Reprogramado</option>
                      <option value="Cumplido">Cumplido</option>
                    </select>
                  </Col>
                </FormGroup>
              ) : null}
            </div>
          ) : null}
        </div>
      </Form>
      {!editar ? (
        <Button
          color="primary"
          className="w-100"
          disabled={loadingCrearProgramacion ? true : false}
          onClick={(e) => {
            guardarProgramacion();
          }}
        >
          Guardar nueva Programacion
        </Button>
      ) : (
        <Button
          color="primary my-4"
          className="w-100"
          disabled={loadingCrearProgramacion ? true : false}
          onClick={(e) => {}}
        >
          Guardar Cambios
        </Button>
      )}
    </div>
  );
};

export default CrearEditarProgramacion;
