//import React from 'react';

import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Table,
  Col,
} from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import axios from "../config/Axios";

import logo_mg from "../svg/img/logo_mg.jpg";

//import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const AnalisisAbc = () => {
  const [desde, setdesde] = useState("");
  const [hasta, sethasta] = useState("");
  const [abc, setabc] = useState([]);
  const [detalleabc, setdetalleabc] = useState([]);

  const [ModalIs, setModalIs] = useState(false);
  const [total, setTotal] = useState(0.0);

  const Reporte_abc = async () => {
    //llamar reporte gastos abc
    const url = "/abcgastos";
    const abc2 = await axios.post(url, { hasta, desde });
    setabc(abc2.data);
    let aux = 0.0;
    if (abc2.data.length > 0) {
      abc2.data.map((data) => (aux = aux + data.Total_gastos));
      setTotal(aux);
    }
  };

  const VerAnaliz = async (cuenta) => {
    const rest = await axios.post("/detalleabc", {
      cuenta,
      desde,
      hasta,
    });
    setdetalleabc(rest.data);

    setModalIs(!ModalIs);
  };

  return (
    <>
      <div> Productos </div>
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" align="center">
                    Analisis ABC
                  </CardTitle>
                  <p className="card-category" align="center">
                    Tabla de datos
                  </p>
                  <div className="bg-transparent" align="center">
                    <div className="form-group row justify-content-between bg-transparent">
                      <div className="col-xl-12" align="center">
                        <div className="row justify-content-center">
                          <div className="col-md-2">
                            <div className="form-group mt-2">
                              <input
                                type="date"
                                className="form-control"
                                id="desde"
                                onChange={(e) => setdesde(e.target.value)}
                              />
                              <label
                                htmlFor="desde"
                                className="form-date-label font-bold"
                              >
                                Desde
                              </label>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group mt-2">
                              <input
                                type="date"
                                className="form-control"
                                id="hasta"
                                onChange={(e) => sethasta(e.target.value)}
                              />
                              <label
                                htmlFor="hasta"
                                className="form-date-label font-bold"
                              >
                                Hasta
                              </label>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-primary"
                              onClick={() => Reporte_abc()}
                            >
                              GENERAR REPORTE
                            </button>
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    <Col md="12">
                      <button className="mr-3 btn btn-danger">PDF</button>
                      {/* <button className="mr-3 btn btn-primary">DOC</button> */}
                      {/*<ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-success"
                  table="reporte"
                  filename="Ventas_xls"
                  sheet="Reporte_Vxls"
                  buttonText="XLS"
                />*/}
                    </Col>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="justify-content-centercol-md-8 ">
                      <div
                        style={{
                          border: "2px solid #C9CBC5",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <Row className="justify-content-center">
                          <Col md="4">
                            <img src={logo_mg} alt="logo" />
                          </Col>
                        </Row>
                        <Table responsive id="reporte">
                          <thead>
                            <th>CUENTA GASTO</th>
                            <th>CANTIDAD</th>
                          </thead>
                          <tbody>
                            {abc.map((iten) => (
                              <tr>
                                <td>{iten.cuenta_gast}</td>
                                <td>{iten.Total_gastos}</td>
                                <td>
                                  <button
                                    className="btn btn-info"
                                    onClick={() => VerAnaliz(iten.cuenta_gast)}
                                  >
                                    <i className="nc-icon nc-zoom-split"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col className="col-md-4 ">
                      <div className="card ">
                        <div className="card-body borde-tarjeta">
                          <div className="d-flex justify-content-center flex-column align-items-center">
                            <div>Total de gastos</div>
                            <div>{total.toFixed(2)}</div>
                          </div>
                          <div>80% = {(total * 0.8).toFixed(2)}</div>
                          <div>15% ={(total * 0.15).toFixed(2)}</div>
                          <div> 5% ={(total * 0.05).toFixed(2)}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal isOpen={ModalIs} md="xl">
          <ModalHeader>
            <div className="justify-content-center">
              <h3 className="text-center">Detalle ABC</h3>
            </div>
          </ModalHeader>
          <ModalBody></ModalBody>
          <Table>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Cuenta</th>
                <th>Detalle</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {detalleabc.map((iten) => (
                <>
                  <tr>
                    <td>{iten.fecha_gast.split("T", 1)}</td>
                    <td>{iten.cuenta_gast}</td>
                    <td>{iten.descripcion_gast}</td>
                    <td>{iten.egreso_gast.toFixed(2)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          <ModalFooter>
            <div className="justify-content-end">
              <Button color="danger" onClick={() => setModalIs(!ModalIs)}>
                Cerrar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    </>
  );
};

export default AnalisisAbc;
