import React, { useState, useEffect } from "react";
import DataTableDefault from "../ReactDataTable";
import { allUsuarios } from "../../api/usuarios";
import moment from "moment";
import ModalDefault from "../modalDefault";
import ModalEliminar from "../modalEliminar";
import { ReactComponent as AdminIcon } from "../../svg/admin.svg";
import CrearEditarUsuarios from "./crearEditarUsuario";
const TablaPanelUsuarios = (props) => {
  const [usuarios, setUsuarios] = useState([]);
  const [modalPerfil, setModalPerfil] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [selectData, setselectData] = useState(null);

  const columns = [
    {
      name: "usuarios",
      center: true,
      selector: (row) => row.usuarios,
    },
    {
      name: "correo",
      center: true,
      cell: (row) =>
        row.personas.length > 0 ? row.personas[0].correo : "Sin correo",
    },
    {
      name: "nivel",
      center: true,
      selector: (row) => row.nivel,
    },
    {
      name: "Estado",
      center: true,
      selector: (row) => row.estado,
      cell: (row) => (row.estado === 1 ? "Inactivo" : "Activo"),
    },
    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    },
  ];

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={() => {
              setselectData(row);
              setModalPerfil(true);
            }}
          >
            edit
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalEliminar(true);
            }}
          >
            Trash
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    initComponent();
  }, [props.actualizar]);

  const initComponent = async () => {
    const respuesta = await allUsuarios();
    console.log(respuesta);
    if (respuesta[0].sms === "ok") {
      setUsuarios(respuesta[0].data);
      return;
    }
  };

  const modalActions = (modal, bandera) => {
    if (bandera === "perfil") {
      setModalPerfil(modal);
    } else if (bandera === "eliminar") {
      setModalEliminar(modal);
    } else {
      alert("estoy fuera del perfil");
    }
  };

  return (
    <>
      <DataTableDefault
        data={usuarios}
        columns={columns}
        title={"Listado de Usuarios"}
      />
      <ModalDefault
        bandera={"perfil"}
        modal={modalPerfil}
        size={"lg"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`Perfil de ${selectData ? selectData.usuarios : ""}`}
        closeAction={modalActions}
      >
        <CrearEditarUsuarios selectData={selectData} />
      </ModalDefault>

      <ModalEliminar
        bandera={"eliminar"}
        modal={modalEliminar}
        size={"lg"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`Eliminar usuario de  ${selectData ? selectData.usuarios : ""}`}
        closeAction={modalActions}
      >
        <CrearEditarUsuarios selectData={selectData} />
      </ModalEliminar>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaPanelUsuarios;
