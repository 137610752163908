import React, { useState, useEffect } from "react";
import ReactToPdf from "react-to-pdf";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Table,
  Col,
} from "reactstrap";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
import logo_mg from "../svg/img/logo_mg.jpg";
import axios from "../config/Axios";
import { Alert } from "react-bootstrap";

const ref = React.createRef();

function ReporteCaja() {
  const [desde, setdesde] = useState("");
  const [hasta, sethasta] = useState("");
  const [caja, setventa] = useState([]);
  const [ventas, setventa1] = useState([]);
  const [compras, setcompra] = useState([]);
  const [ingresos, setingresos] = useState([]);
  const [gastos, setgastos] = useState([]);
  const [egresos, setEgresos] = useState([]);
  const [egresos_tot, setEgresos_tot] = useState(0);
  const [egresos_tot22, setEgresos_tot22] = useState(0);
  const [porpagar, setporpagar] = useState([]);
  const [X_pagar, setX_pagar] = useState(0);
  const [X_compras, setX_compras] = useState(0);
  const [contador, setContador] = useState(0);

  const [acumulador, setacumulador] = useState(0);
  const [TotalEgreso, setTotalEgreso] = useState("");

  //resetearVariables();

  /* const resetearVariables = () => {
    alert("ingresando a resetear las variables");
    //setventa(0);
    setventa1(0);
    setcompra(0);
    setingresos(0);
    setgastos(0);
    setEgresos(0);
    setEgresos_tot(0);
    setEgresos_tot22(0);
    setporpagar(0);
    setX_pagar(0);
    setX_compras(0);
    setacumulador(0);
    setTotalEgreso(0);
  }; */
  useEffect(() => {
    console.log("el useEffect");
    setEgresos_tot22(0);
    console.log("el acumulador deberia ser 0 " + egresos_tot22);
  }, [contador]);

  const Reporte_C = async () => {
    console.log(contador);
    const caja = await axios.post("/reportecaja/saldoanterior", {
      hasta,
      desde,
    });
    setventa(caja.data);
    const cero = 0;
    let valo1 = 0;
    let valo2 = 0;
    let valo3 = 0;

    valo1 =
      caja.data[0].saldo_anterior === null
        ? 0.0
        : parseFloat(caja.data[0].saldo_anterior);
    valo2 = 0;
    //llamr ingresos para el reporte de caja
    const url3 = "/reportecaja/ingresostot";
    const ingresos = await axios.post(url3, { hasta, desde });

    setingresos(ingresos.data);
    var suma = 0;
    const response = ingresos.data.map(
      (iten) => (suma += iten.Total_ingresos2)
    );

    valo1 += parseFloat(suma) === "" ? 0.0 : parseFloat(suma);
    //llamar total de ventas para el reporte de caja

    const url = "/reportecaja/ventastot";
    const ventas = await axios.post(url, { hasta, desde });
    setventa1(ventas.data);
    valo1 +=
      ventas.data[0].total_venta === null
        ? 0.0
        : parseFloat(ventas.data[0].total_venta);
    setacumulador(valo1);
    setEgresos_tot();
    //llamar total de compras para el reporte de caja

    const url2 = "/reportecaja/comprastot";
    const compras = await axios.post(url2, { hasta, desde });

    let restcompras = 0;
    compras.data.map((iten) => (restcompras = restcompras + iten.total_pagar));
    console.log("nuevo total compras..." + restcompras);
    let valo4 = restcompras;

    if (compras.length === 0) {
      setcompra(0);
    } else {
      console.log("Compras", compras.data);
      valo2 +=
        compras.data[0].total_pagar === null ||
        compras.data[0].total_pagar.lenght > 0
          ? 0.0
          : parseFloat(compras.data[0].total_pagar);
      setcompra(compras.data);
      let rest = 0;
      compras.data.map((iten) => (rest = rest + iten.total_pagar));
      setX_compras(rest);
      console.log("============================");
      console.log("total compras", valo4);
    }
    //llamr ingresos para el reporte de caja
    const url4 = "/reportecaja/gastostot";
    const gastos = await axios.post(url4, { hasta, desde });
    setgastos(gastos.data);
    valo2 = parseFloat(gastos.data[0].Total_gastos2);

    console.log("total  gastos", valo2);
    //llamr egresos para el reporte de caja
    const url6 = "/reportecaja/otrosegresostot";
    const egresos = await axios.post(url6, { hasta, desde });
    setEgresos(egresos.data);
    console.log("valor otros egresos" + egresos.data);
    valo3 +=
      egresos.data[0] === null
        ? 0.0
        : parseFloat(egresos.data[0].Total_egresos2);

    //valo3 += parseFloat(egresos.data[0].Total_egresos2);
    //alert(valo3);
    let rest1 = 0;
    egresos.data.map((iten) => (rest1 = rest1 + iten.Total_egresos));
    valo3 = rest1;
    setX_pagar(valo3);
    //alert("total ingresos " + acumulador);

    /* alert("compras " + X_compras);
    alert("gastos " + valo2);
    alert("otros egresos " + rest1); */

    setEgresos_tot22(
      parseFloat(restcompras) + parseFloat(valo2) + parseFloat(rest1)
    );

    console.log("total otros egresos", rest1);
    console.log("total totales egresos", egresos_tot22);

    const url5 = "/reportecaja/porpagartot";
    const porpagar = await axios.post(url5, { hasta, desde });
    if (porpagar.lenght === 0) {
      setporpagar(0);
    } else {
      console.log("Por Pagar", porpagar.data);
      setporpagar(porpagar.data);
      // valo2 += porpagar.data[0].total_por_pagar
    }
  };

  var TOTALEgreso = 0;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Tabla Reporte</CardTitle>
                <p className="card-category">Reporte</p>
                <div className="bg-transparent" align="center">
                  <div className="form-group row justify-content-between bg-transparent">
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group mt-2">
                            <input
                              type="date"
                              className="form-control"
                              id="desde"
                              onChange={(e) => setdesde(e.target.value)}
                            />
                            <label
                              htmlFor="desde"
                              className="form-date-label font-bold"
                            >
                              Desde
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mt-2">
                            <input
                              type="date"
                              className="form-control"
                              id="hasta"
                              onChange={(e) => sethasta(e.target.value)}
                            />
                            <label
                              htmlFor="hasta"
                              className="form-date-label font-bold"
                            >
                              Hasta
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setContador(contador + 1);
                              Reporte_C();
                            }}
                          >
                            GENERAR REPORTE
                          </button>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>

          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">
                  <Col md="12">
                    <ReactToPdf
                      targetRef={ref}
                      filename="Reporte_caja.pdf"
                      x={0.5}
                      y={0.5}
                      scale={0.8}
                    >
                      {({ toPdf }) => (
                        <button className="mr-3 btn btn-danger" onClick={toPdf}>
                          PDF
                        </button>
                      )}
                    </ReactToPdf>
                    {/* <button className="mr-3 btn btn-primary">DOC</button> */}
                    {/*<ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-success"
                      table="Caja"
                      filename="Caja"
                      sheet="Caja"
                      buttonText="XLS"
                    />*/}
                  </Col>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <div
                    style={{
                      border: "2px solid #C9CBC5",
                      padding: "10px",
                      width: "70%",
                    }}
                  >
                    <div ref={ref} className="pdf">
                      <Row className="justify-content-center">
                        <Col md="4">
                          <img src={logo_mg} alt="logo" />
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md="4">
                          <label
                            htmlFor="hasta"
                            className="form-date-label font-bold"
                          >
                            <h4>Reporte de Caja </h4>
                          </label>
                        </Col>
                      </Row>
                      <br></br>
                      <hr></hr>
                      <Row className="justify-content-center">
                        <Col md="4">
                          <label
                            htmlFor="hasta"
                            className="form-date-label font-bold"
                          >
                            Desde: {desde}
                          </label>
                        </Col>
                        <Col md="4">
                          <label
                            htmlFor="hasta"
                            className="form-date-label font-bold"
                          >
                            Hasta: {hasta}
                          </label>
                        </Col>
                      </Row>
                      <br></br>
                      <hr></hr>
                      <Row className="justify-content-center">
                        <Col md="8">
                          <Table hover responsive id="Caja" className="table">
                            <thead>
                              <th className="text-center">INGRESO</th>
                              <th className="text-center">DETALLE</th>
                              <th className="text-center">PESO</th>
                              <th className="text-center">PAGADO</th>
                            </thead>
                            <tbody>
                              {caja.map((iten) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{ padding: "0" }}
                                  ></td>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  >
                                    Saldo anterior
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  ></td>
                                  <td
                                    className="text-center"
                                    style={{ padding: "0" }}
                                  >
                                    ${iten.saldo_anterior}
                                  </td>
                                </tr>
                              ))}
                              {ingresos.map((iten) => (
                                <tr>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  ></td>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  >
                                    {iten.cuenta_gast}
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  ></td>
                                  <td
                                    className="text-center"
                                    style={{ padding: "0" }}
                                  >
                                    $
                                    {parseFloat(iten.Total_ingresos2).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {ventas.map((iten) => (
                                <>
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-left"
                                      style={{ padding: "0" }}
                                    >
                                      ventas
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      ${parseFloat(iten.total_venta).toFixed(2)}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      TOTAL INGRESOS
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      {acumulador === 0
                                        ? ""
                                        : "$" +
                                          parseFloat(acumulador).toFixed(2)}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      EGRESOS
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                  </tr>
                                </>
                              ))}
                              {compras.map((iten) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{ padding: "0" }}
                                  ></td>
                                  <td
                                    className="text-left"
                                    style={{ padding: "0" }}
                                  >
                                    {iten.material}
                                  </td>
                                  <td
                                    className="text-right"
                                    style={{ padding: "0" }}
                                  >
                                    {iten.Total_kilos}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ padding: "0" }}
                                  >
                                    ${parseFloat(iten.total_pagar).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                              {gastos.map((iten) => (
                                <>
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-left"
                                      style={{ padding: "0" }}
                                    >
                                      Gastos
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      $
                                      {parseFloat(iten.Total_gastos2).toFixed(
                                        2
                                      )}
                                    </td>
                                  </tr>
                                  {egresos.map((iten) => (
                                    <tr>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0" }}
                                      ></td>
                                      <td
                                        className="text-left"
                                        style={{ padding: "0" }}
                                      >
                                        {iten.cuenta_gast}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0" }}
                                      ></td>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0" }}
                                      >
                                        $
                                        {parseFloat(
                                          iten.Total_egresos2
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      TOTAL EGRESOS
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      ${parseFloat(egresos_tot22).toFixed(2)}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      POR PAGAR
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                  </tr>

                                  {porpagar.map((iten) => (
                                    <tr>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0" }}
                                      ></td>
                                      <td
                                        className="text-left"
                                        style={{ padding: "0" }}
                                      >
                                        {iten.cliente}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0", align: "right" }}
                                      >
                                        {iten.Total_kilos}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: "0", align: "right" }}
                                      >
                                        $
                                        {parseFloat(iten.total_pagar).toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                  <br></br>
                                  <tr>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      <h4>
                                        <b>SALDO EN CAJA</b>
                                      </h4>
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                    <th
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    >
                                      <h4>
                                        <b>
                                          $
                                          {(
                                            parseFloat(acumulador) -
                                            parseFloat(egresos_tot22)
                                          ).toFixed(2)}
                                        </b>
                                      </h4>
                                    </th>
                                    <td
                                      className="text-center"
                                      style={{ padding: "0" }}
                                    ></td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReporteCaja;
