import React, { useState, useEffect } from "react";
// react plugin used to create charts
import { Bar, Line, Pie } from "react-chartjs-2";

import GraficoBarras from "../Componentes/Graficos/barChar";
import GraficoBarrasVentas from "../Componentes/Graficos/barCharVentas";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import { ReactComponent as ComprasSgv } from "../svg/centercode-brands.svg";
import { ReactComponent as VentasSgv } from "../svg/detalles.svg";
//import CardIcon from "../Componentes/Card/CardIcon/CardIcon.js";

/* import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js"; */
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "../variables/charts.js";
import axios from "../config/Axios";

function Dashboard() {
  const [total_compras, setTotalCompras] = useState([]);
  const [total_ventas, setTotalVentas] = useState([]);
  const [total_gastos, setTotalGastos] = useState([]);
  const [saldo_anterio, setsaldoAnterios] = useState([]);

  const [label, setlabel] = useState([]);
  const [label1, setlabel1] = useState([]);
  const [peso, setpeso] = useState([]);
  const [peso1, setpeso1] = useState([]);

  const FillDash = async () => {
    const fecha = new Date();
    let mes_actual = fecha.getMonth() + 1;
    let dia_actual = fecha.getDate();
    let anio_actual = fecha.getFullYear();
    console.log(anio_actual + "/" + mes_actual + "/" + dia_actual);
    let fecha_hasta = "2021-01-05";
    let fecha_desde = "2021-01-05";

    const res = await axios.get("/total_compras");
    setTotalCompras(res.data);
    console.log(res.data);

    const res2 = await axios.get("/total_ventas");
    setTotalVentas(res2.data);

    const res3 = await axios.get("/total_gastos");
    setTotalGastos(res3.data);
    console.log(res3.data);

    const res4 = await axios.get("/saldos_diarios", {
      fecha_hasta,
      fecha_desde,
    });
    setsaldoAnterios(res4.data);
    console.log(res4.data);
  };

  const BarCharts = async () => {
    const rest = await axios.get("/charts");
    let auxsub = [];
    let auxpeso = [];
    rest.data.map((iten) => auxsub.push(iten.sub_linea));
    await rest.data.map((iten) => auxpeso.push(iten.peso));
    setpeso(auxpeso);
    setlabel(auxsub);

    /* console.log("auxsub .. " + auxsub);
    console.log("auxpeso .. " + auxpeso); */
  };
  const BarChartsVentas = async () => {
    //alert("en grafico ventas");
    const rest1 = await axios.get("/chartsventas");
    let auxsub1 = [];
    let auxpeso1 = [];
    rest1.data.map((iten) => auxsub1.push(iten.sub_linea));
    await rest1.data.map((iten) => auxpeso1.push(iten.peso));
    setpeso1(auxpeso1);
    setlabel1(auxsub1);

    /* console.log("auxsub .. " + auxsub);
    console.log("auxpeso .. " + auxpeso); */
  };

  useEffect(() => {
    FillDash();
    BarCharts();
    BarChartsVentas();
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats sombra"
              style={{ backgroundColor: "MediumSeaGreen", color: "white" }}
            >
              <CardBody>
                <Row>
                  {/* <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col> */}
                  <Col md="8" xs="7">
                    <div className="numbers d-flex justify-content-between align-items-center">
                      <ComprasSgv className="dashboardicon" fill="white" />
                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <p className="card-category">COMPRAS</p>
                        <CardTitle tag="p">
                          {total_compras.map((iten) => iten.total_pagar)}
                        </CardTitle>
                        <p />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="stats p-2">
                  <i className="far fa-calendar" /> Total Compras
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats sombra"
              style={{ backgroundColor: "DodgerBlue", color: "white" }}
            >
              <CardBody>
                <Row>
                  <Col md="8" xs="7">
                    <div className="numbers d-flex justify-content-between align-items-center">
                      <VentasSgv className="dashboardicon" fill="white" />
                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <p className="card-category">VENTAS</p>
                        <CardTitle tag="p">
                          {total_ventas.map((iten) => iten.total_pagar)}
                        </CardTitle>
                        <p />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="stats p-2">
                  <i className="far fa-calendar" /> Total Ventas
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats sombra"
              style={{ backgroundColor: "orange", color: "white" }}
            >
              <CardBody>
                <Row>
                  <Col md="8" xs="7">
                    <div className="numbers d-flex justify-content-between align-items-center">
                      <VentasSgv className="dashboardicon" fill="white" />
                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <p className="card-category">GASTOS</p>
                        <CardTitle tag="p">
                          {total_gastos.map((iten) => iten.Total_gastos)}
                        </CardTitle>
                        <p />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="stats p-2">
                  <i className="far fa-calendar" /> Total Gastos
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats sombra"
              style={{ backgroundColor: "Tomato", color: "white" }}
            >
              <CardBody>
                <Row>
                  <Col md="8" xs="7">
                    <div className="numbers d-flex justify-content-between align-items-center">
                      <VentasSgv className="dashboardicon" fill="white" />
                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <p className="card-category">Egresos</p>
                        <CardTitle tag="p">
                          {saldo_anterio.map((iten) => iten.Total_gastos)}
                        </CardTitle>
                        <p />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="stats p-2">
                  <i className="far fa-calendar" /> Total Egresos
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col lg="6" md="6" sm="6">
            <Card>
              <GraficoBarras label={label} pesos={peso} />
            </Card>
          </Col>
          <Col lg="6" md="6" sm="6">
            <Card>
              <GraficoBarrasVentas label={label1} pesos={peso1} />
            </Card>
          </Col>
        </Row>
        {/* <Row>
           <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Users Behavior</CardTitle>
                  <p className="card-category">24 Hours performance</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i  className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row> */}
        {/* <Row>
          {
            <Col md="12 py-5">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
                  <p className="card-category">Line Chart with Points</p>
                </CardHeader>
                <CardBody>
                  {label.length > 0 && peso.length > 0 ? (
                    <Bar
                      data={{
                        labels: label,
                        datasets: [
                          {
                            label: "#of Votes",
                            data: peso,
                            backgroundColor: [
                              "#088EF7",
                              "#F73208",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                              "#313131",
                              "#F73208",
                              "#F7E108",
                              "rgba(255, 206, 86, 0.2)",
                              "#08F7B6",
                              "#C108F7",
                              "#5B0214",
                              "rgba(255, 206, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 99, 132, 0.2)",
                            ],
                          },
                        ],
                      }}
                      width={400}
                      height={100}
                      // options={{ maintainAspectRatio: false }}
                    />
                  ) : (
                    <div>No hay data que mostrar</div>
                  )}
                </CardBody>
                <CardFooter>
                  <div className="chart-legend">
                    <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                    <i className="fa fa-circle text-warning" /> BMW 5 Series
                  </div>
                  <hr />
                  <div className="card-stats">
                    <i className="fa fa-check" /> Data information certified
                  </div>
                </CardFooter>
              </Card>
            </Col>
          }
        </Row> */}
      </div>
    </>
  );
}

export default Dashboard;
